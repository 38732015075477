<template>
  <div class="order-wraper">
    <div class="order-caption">
      <div class="caption">
        <strong class="order-title">车辆维修单</strong>
        <div class="order-pay">
          <span v-if="order.status=='-1'" style="color:#999999;">已取消</span>
          <span v-else-if="!order.payStatus">待付款</span>
          <span v-else style="color:#999999;">已付款</span>
        </div>
      </div>
      <div class="licence-plate">
        <span class="licence-plate-title">{{order.carCode}}</span>
      </div>
    </div>
    <div class="dashed-line"></div>
    <div class="expenditure-wraper">
      <div class="expenditure">
        <span class="expenditure-title">消费金额：</span>
        <span class="expenditure-content">{{convert(order.totalAmount)}}元</span>
      </div>
      <div class="expenditure">
        <span class="expenditure-title">消费时间：</span>
        <span class="expenditure-content">{{formatDate(order.maintainTime || '')}} </span>
      </div>
    </div>
    <div class="dashed-line"></div>
    <template v-if="!order.isShow">
      <div class="part-and-task">
        <ul class="part-and-task-title">
          <li>配件/工时清单</li>
          <li>数量/单价</li>
          <li>小计</li>
        </ul>
        <ul class="part-wraper" v-for="(item) in order.serviceProductList" :key="item.id">
          <li>{{item.productName}}</li>
          <li>{{item.productQuantity}} x {{convert(item.productPrice)}} 元</li>
          <li>{{convert(item.totalAmount)}}元</li>
        </ul>
        <ul class="task-wraper" v-for="(item) in order.serviceTaskList" :key="item.id">
          <li>{{item.taskName}}</li>
          <li>{{item.taskNum}} x {{convert(item.taskFee)}}元</li>
          <li>{{convert(item.totalAmount)}}元</li>
        </ul>
      </div>
      <div class="dashed-line"></div>
    </template>
    <div class="order-summation">
      <div class="order-summation-content">
        <span>配件合计：</span>
        <span>{{convert(order.productAmount)}}元</span>
      </div>
      <div class="order-summation-content">
        <span>工时合计：</span>
        <span>{{convert(order.taskAmount)}}元</span>
      </div>
      <div class="order-summation-content">
        <span>优惠金额：</span>
        <span>{{convert(order.garageDiscountAmount)}}元</span>
      </div>
    </div>
    <!-- <div class="dashed-line"></div> -->
    <!-- 锯齿效果 -->
    <div class='semicircle-box'>
      <img class="wraper-bian-img img-left" src='~images/other/ear-left.png'/>
      <img class='wraper-bian-img img-right' src='~images/other/ear-right.png'/>
    </div>
    <!-- 锯齿效果 -->
    <div class="order-total">
      <span class="order-total-title">总计:</span>
      <span class="order-total-content">{{convert(order.totalAmount)}}元</span>
    </div>
    <div class="garage-info-wraper">
      <div class="garage-info">
        <strong class="garage-info-title">服务门店：</strong>
        <span class="garage-info-content">{{garageInfo.companyName}}</span>
      </div>
      <div class="garage-info">
        <strong class="garage-info-title">门店电话：</strong>
        <span class="garage-info-content">{{garageInfo.managerPhone}}</span>
      </div>
      <div class="garage-info">
        <strong class="garage-info-title">门店地址：</strong>
        <span class="garage-info-content">{{garageInfo.address}}</span>
      </div>
      <div class="garage-info">
        <strong class="garage-info-title">温馨提示：</strong>
        <div class="garage-info-content">
          <span>当前行驶里程 {{order.maintainMileage || 0}} km</span>
          <span>下次服务里程 {{order.nextMileage || 0}} km</span>
          <span>下次服务时间 {{formatDate(order.nextUpkeepTime || '-')}}</span>
        </div>
      </div>
      <div class="garage-info">
        <strong class="garage-info-title">备 注：</strong>
        <div class="garage-info-content-remark">
          <span v-if="order.suggestion">{{order.suggestion}}</span>
          <span v-else style="color:#999999;">暂无</span>
        </div>
      </div>
    </div>
    <!-- 锯齿 -->
    <div class="sawtooth-bottom-wraper"> <img class="sawtooth-bottom" src='~images/other/sawtooth-bottom.png'/>
    </div>
    <!-- 锯齿 -->
  </div>
</template>

<script>
import { orderDetail, garageDetail } from 'api/order.js'
export default {
  data () {
    return {
      order: '',
      garageInfo: {}
    }
  },
  mounted () {
    let id = this.$route.query.id || ''
    this.getInitOrder(id)
  },
  methods: {
    getInitOrder (id) {
      orderDetail(id).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.order = res.data.data
        this.getGarage(this.order.companyId)
      })
    },
    getGarage (companyId) {
      let params = {
        companyId: companyId
      }
      garageDetail(JSON.stringify(params)).then((res) => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.garageInfo = res.data.data
      })
    },
    convert (money = 0) {
      return parseFloat(money).toFixed(2)
    },
    formatDate (date) {
      return date.substring(0, 10)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~styles/index.scss";
.order-wraper {
  margin: px2rem(30px) px2rem(40px) px2rem(30px);
  padding: px2rem(40px);
  font-family: PingFangSC;
  background: #fff;
  border-radius: px2rem(20px);
  box-shadow: 0px px2rem(2px) px2rem(18px) 0px rgba(87, 87, 87, 0.1);
  position: relative;
  .dashed-line {
    border-bottom: px2rem(2px) dashed #e4e4e4;
  }
  .order-caption {
    .caption {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .order-title {
        font-size: px2rem(36px);
        color: #000000;
        font-weight: bold;
        font-family: PingFangSC;
      }
      .order-pay {
        font-size: px2rem(24px);
        color: #ff9d4d;
      }
    }
    .licence-plate {
      margin: px2rem(20px) 0 px2rem(12px) 0;
      font-size: 0;
      .licence-plate-title {
        font-size: px2rem(30px);
        color: #000000;
      }
    }
  }
  .expenditure-wraper {
    padding: px2rem(30px) 0;
    .expenditure:nth-child(1) {
      margin-bottom: px2rem(10px);
    }
    .expenditure {
      font-size: px2rem(24px);
      color: #363636;
      display: flex;
      height: px2rem(33px);
      line-height: px2rem(33px);
      .expenditure-title {
        width: px2rem(120px);
      }
      .expenditure-content {
      }
    }
  }
  .part-and-task {
    padding: px2rem(30px) 0;
    .part-and-task-title {
      display: flex;
      height: px2rem(33px);
      line-height: px2rem(33px);
      li {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-size: px2rem(24px);
        color: #363636;
        font-weight: bold;
      }
      li:nth-child(1) {
        justify-content: flex-start;
      }
    }
    .part-wraper {
      display: flex;
      margin-top: px2rem(20px);
      height: px2rem(33px);
      line-height: px2rem(33px);
      li {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-size: px2rem(24px);
        color: #999999;
      }
      li:first-child {
        justify-content: flex-start;
      }
      li:last-child {
        color: #363636;
      }
    }
    .task-wraper {
      display: flex;
      margin-top: px2rem(20px);
      height: px2rem(33px);
      line-height: px2rem(33px);
      li {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        font-size: px2rem(24px);
        color: #999999;
      }
      li:first-child {
        justify-content: flex-start;
      }
      li:last-child {
        color: #363636;
      }
    }
  }
  .order-summation {
    padding: px2rem(30px) 0;
    font-size: px2rem(24px);
    color: #999999;
    .order-summation-content {
      margin-top: px2rem(10px);
      display: flex;
      justify-content: space-between;
      height: px2rem(33px);
      line-height: px2rem(33px);
      span:nth-child(1) {
        width: px2rem(120px);
      }
      span:last-child {
        color: #363636;
      }
    }
    .order-summation-content:last-child {
      span:nth-child(2) {
        margin-left: px2rem(-220px);
      }
    }
  }
  .order-total {
    padding: px2rem(30px) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #363636;
    .order-total-title {
      font-size: px2rem(24px);
      font-weight: bold;
    }
    .order-total-content {
      font-size: px2rem(36px);
      font-weight: bold;
    }
  }
  .garage-info-wraper {
    .garage-info {
      margin-bottom: px2rem(10px);
      padding: px2rem(4.5px) 0;
      display: flex;
      font-size: px2rem(24px);
      .garage-info-title {
        min-width: px2rem(120px);
        color: #363636;
        font-weight: bold;
      }
      .garage-info-content {
        color: #999999;
        display: flex;
        flex-direction: column;
        span {
          margin-bottom: px2rem(10px);
          padding: px2rem(4.5px) 0;
        }
      }
      .garage-info-content-remark {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #363636;
      }
    }
  }
  .semicircle-box {
    border-bottom: px2rem(2px) dashed #e4e4e4;
    position: relative;
    .wraper-bian-img {
      width: px2rem(17px);
      height: px2rem(35px);
    }
    .img-left {
      position: absolute;
      left: px2rem(-40px);
      top: 0;
      transform: translate(0, -50%);
    }
    .img-right {
      position: absolute;
      right: px2rem(-40px);
      top: 0;
      transform: translate(0, -50%);
    }
  }
  .sawtooth-bottom-wraper {
    position: absolute;
    bottom: px2rem(-12px);
    left: 0;
    .sawtooth-bottom {
      width: px2rem(670px);
      height: px2rem(14px);
    }
  }
}
</style>
